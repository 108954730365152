import React, {useLayoutEffect, useMemo, useState} from "react";
import {useHistory} from "react-router-dom";
import {observer} from "mobx-react";
import {Button, CircularProgress} from "@material-ui/core";
import {CircleFlag} from "react-circle-flags";
import {AxiosResponse} from "axios";

import {useStores} from "../../store/useStore";
import {TActivatedSub, TSubscription} from "../../store/types";
import {ROUTES} from "../../util/routes";
import {CONSTANTS, postDOMForm} from "../../util/utils";
import {getCountryName} from "../../util/countries";
import strings from "../../util/i18n/strings";

import s from './Subscription.module.scss'

const Subscription = observer(() => {

  const {
    subscriptionStore: {
      subscriptions,
      activateSubscriptionAsync,
      getSubscriptionsAsync
    },
    modalsStore: {
      replaceLocationSearch
    }
  } = useStores();

  let history = useHistory();

  useLayoutEffect(() => {
    getSubscriptionsAsync()
  }, [])

  const goToMain = () => {
    history.push(ROUTES.ROOT)
  }

  const {loading, list} = subscriptions;

  const [item, setItem] = useState<TSubscription | null>(null);
  const [loader, setLoader] = useState<boolean>(true);

  const [loadingPayment, setLoadingPayment] = useState<boolean>(false);

  const isLoading = useMemo(() => {
    if (!loading) {
      return loader
    }
    if (loader) {
      return loading
    }
  }, [loader, loading])

  useLayoutEffect(() => {
    return () => {
      replaceLocationSearch(true)
    }
  }, [])

  useLayoutEffect(() => {
    if (list.length > 0) {
      const active = list.find((el) => el.active);
      if (active) {
        history.push(ROUTES.ROOT);
      }
      const search = new URLSearchParams(window.location.search);
      const offer = search.get(CONSTANTS.WELCOME_OFFER);
      if (offer) {
        const findItem = list.find((el) => el.country.toLowerCase() === offer.toLowerCase());
        if (findItem && findItem.isWelcomeOffer) {
          setItem(findItem);
          setLoader(false);
        } else {
          history.push(ROUTES.ROOT)
        }
      } else {
        history.push(ROUTES.ROOT)
      }
    }
  }, [list])

  const activatedSubscription = () => {
    if (item?.id) {
      setLoadingPayment(true);
      activateSubscriptionAsync(item.id)
        .then((res: AxiosResponse<TActivatedSub>) => {
          const {data: {paymentRequest}} = res;
          const {method, params, url} = paymentRequest;
          if (method.toUpperCase() === "POST") {
            postDOMForm(url, params)
          } else {
            const link = document.createElement('a');
            link.href = url;
            link.click();
          }
        })
    }
  }

  const getLink = () => {
    switch (strings.getLanguage()) {
      case 'ru':
        return 'https://yollacalls.ru/credentials-storage-agreement-new/';
      case 'de':
        return 'https://yollacalls.de/credentials-storage-agreement-new/';
      case 'fr':
        return 'https://yollacalls.fr/credentials-storage-agreement-new/';
      default:
        return 'https://yollacalls.com/credentials-storage-agreement-new/'
    }
  }

  const link = useMemo(() => {
    return getLink()
  }, [])

  return (
    <div className={s.bg}>
      {isLoading ? (
        <section className={s.wrapperLoader}>
          <CircularProgress color="secondary"/>
        </section>
      ) : (
        <section className={s.subscription}>
          <div className={s.maxWrap}>
            <div className={s.title}>
              {`${getCountryName(String(item?.country))} ${strings.subscription.header}`}
            </div>
            <div className={s.wrapper}>
              <div className={s.subtitle}>
                <div className={s.flag}>
                  <CircleFlag countryCode={String(item?.country.toLowerCase())}/>
                </div>
                {`${getCountryName(String(item?.country))} ${strings.subscription.header}`}
              </div>
              <div className={s.contentWrap}>
                <div className={s.duration}><span>{item?.volume} min</span> for 7 days</div>
                <div className={s.volume}>
                  <div className={s.amount}>1$</div>
                  <div>excl. VAT</div>
                </div>
              </div>
              <div className={s.message}>
                <span>
                       {strings.subscription.active_subscription_demo
                         .replace('/amount', item ? `${String(item.renewAmount)}$` : '')
                         .replace('/duration', item ? String(item.renewVolume) : '')
                         .replace('/days', item ? String(item.renewDays) : '').split(',').map((el, index) => (
                           <div key={index}>{`${el},`}</div>
                         ))
                       }
                </span>
                <div className={s.activate}/>
              </div>
              <div className={s.text}>
                {strings.subscription.text_content.split('\n').map((text, idx) => (
                  <p key={idx}>{text}</p>
                ))}
              </div>
              <div className={s.link}>
                <a href={link} target="_blank">
                  {strings.subscription.header_link}
                </a>
              </div>
              <div className={s.button}>
                <Button
                  variant="contained"
                  color="secondary"
                  disabled={loadingPayment}
                  onClick={activatedSubscription}
                >
                  {loadingPayment ?
                    <CircularProgress color="inherit" size={32}/> :
                    `${strings.subscription.btn_text.replace('/number', item ? String(item.amount) : '')}`}
                </Button>
              </div>
            </div>
            <Button className={s.back} onClick={goToMain} disableRipple={true}>
              {strings.header.menu_account}
            </Button>
          </div>
        </section>
      )}
    </div>
  )
})

export default Subscription;
